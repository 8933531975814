(function () {
	'use strict';

	angular.module('app').factory('daybackConfig', ['$sce', daybackConfig]);

	function daybackConfig($sce) {
		return {
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function config() {
			return {
				id: 6,
				name: 'DayBack',
				propertyName: 'dayback',
				getApiConnection: null,
				available: false,
				editable: true,
				isPrimary: true,
				async: true,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/FileMakerBigAlt.png`,
				sourceHelp: $sce.trustAsHtml(
					'<h4>These settings will tell DayBack how to communicate with your hosted FileMaker file.</h4>DayBack requires that your file be hosted with FileMaker Server version 12 or higher and that you have access to place a simple PHP file in the server\'s web publishing folder. (A link to download that file is provided below.)<br><br>You should see your events in DayBack as soon as you complete the settings on this page, including the fields in "Field Mapping" below. Click "Details" for more information about any item below; more details (and video) can be found in our docs here: <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackOnline.Integration" target="blank">detailed instructions</a>.<br><br> And please <a href="http://www.seedcode.com/contact/" target="blank">let us know</a> if you have any questions about this form.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>Please map each of the fields below to a field in your events table.</h4>If you don't have a particular field, or don't want your calendar users to see the field, just ​un-check​ the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work. Note that any fields you reference below will need to be on the \"Source layout name\" layout you specified above."
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				useISOTimestamp: true,
				useDefaultFieldMap: true,
				customActionsEditable: false,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 6,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New DayBack Event Calendar',
						format: 'name',
						helptext:
							'Your own name for these events, like "Company Projects".',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: false,
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'contact', label: 'Contact'},
							{id: 'project', label: 'Project'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
				},
			};
		}

		function fieldMap() {
			return null;
		}

		function unusedMap() {
			return {
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}

		function hiddenFieldMap() {
			return {};
		}

		function readOnlyFieldMap() {
			return {};
		}
	}
})();
